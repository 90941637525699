<template>
  <div id="adList-actionDialog">
    <el-dialog
      title="广告操作"
      :visible.sync="show"
      :close-on-press-escape="false"
      destroy-on-close
      @open="open"
      @close="reset"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="90px">
        <el-form-item label="广告名称" prop="advtisementName">
          <el-input
            v-model="form.advtisementName"
            placeholder="请输入广告名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="广告位置"
          prop="advtisementPosition"
          v-if="!advtisementId"
        >
          <el-select
            v-model="form.advtisementPosition"
            placeholder="请选择广告位置"
            :disabled="
              advtisementType === '区域' || advtisementType === '停车场'
            "
          >
            <el-option
              v-for="item of select_adPosition"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="picFileId">
          <imgUpload ref="imgUpload" @success="imgUploadSuccess" />
        </el-form-item>
        <el-form-item label="富文本">
          <span class="richText-btn" @click="richTextPreview" v-if="form.url"
            >预览</span
          >
          <span class="richText-btn" @click="richTextEdit">编辑</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <richTextDialog ref="richTextDialog" :url="form.url" @save="richTextSave" />
    <richTextPreview ref="richTextPreview" />
  </div>
</template>
<script>
export default {
  components: {
    imgUpload: () => import("@/components/imgUpload.vue"),
    richTextDialog: () => import("./richTextDialog.vue"),
    richTextPreview: () => import("./richTextPreview.vue"),
  },
  props: {
    advtisementType: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      advtisementId: "",
      previewUrl: "",
      form: {
        advtisementName: "",
        advtisementPosition: "",
        picFileId: "",
        remarks: "",
        url: "",
      },
      rules: {
        advtisementName: [
          { required: true, message: "请输入广告名称", trigger: "blur" },
        ],
        advtisementPosition: [
          { required: true, message: "请选择广告位置", trigger: "change" },
        ],
        picFileId: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.form.picFileId) {
                callback(new Error("请至少上传一张图片"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    richTextPreview() {
      this.$refs.richTextPreview.iframe = this.form.url;
      this.$refs.richTextPreview.show = true;
    },
    richTextEdit() {
      this.$refs.richTextDialog.show = true;
    },
    richTextSave(value) {
      this.form.url = value;
    },
    open() {
      if (this.advtisementType === "区域") {
        this.form.advtisementPosition = "首页活动";
      } else if (this.advtisementType === "停车场") {
        this.form.advtisementPosition = "支付广告";
      }
      if (this.previewUrl) {
        this.$nextTick(() => {
          this.$refs.imgUpload.previewUrl = this.previewUrl;
        });
      }
    },
    reset() {
      this.advtisementId = "";
      this.initImg = "";
      this.previewUrl = null;
      this.$set(this, "form", {
        advtisementName: "",
        advtisementPosition: "",
        picFileId: "",
        remarks: "",
        url: "",
      });
      this.$refs["form"].resetFields();
    },
    imgUploadSuccess(fileId) {
      this.form.picFileId = fileId;
    },
    confirmBtn() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let url = "/ad/add";
            let params = {
              ...this.form,
            };

            if (this.advtisementId) {
              url = "/ad/edit";
              params.advtisementId = this.advtisementId;
              delete params["advtisementPosition"];
            } else {
              if (this.advtisementType === "区域") {
                params.areaId = this.$route.params.areaId;
              } else if (this.advtisementType === "停车场") {
                params.parkingLotId = this.$route.params.parkingLotId;
              }
              params.advtisementType = this.advtisementType;
            }

            let res = await this.$http.post(url, params);
            if (res.code === 0) {
              this.$emit("confirm");
              this.$message.success("操作成功");
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
            this.loading = false;
          } catch (err) {
            this.$message.error(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
#adList-actionDialog {
  & > .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
        .richText-btn {
          margin-right: 10px;
          color: rgb(169, 165, 165);
          cursor: pointer;
          &:hover {
            color: $main-color;
          }
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
